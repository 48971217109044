@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Righteous&display=swap');


html, body {
  background-color: #70bedb;
}

.landscapeMode{
  border: none;
  /* border: 3px solid #00ff00; */
}

.portraitMode {
  /* disables scrolling when using the signoff signature component */
  /* Disable rubber-band effect */
  overflow: hidden !important;
  height: 100% !important;
  -webkit-overflow-scrolling: auto !important; 
  /* border: 3px solid #ff0000; */
}


.arrow {
  position: relative;
  z-index: 1;
}

.arrow:after {
  content: '';
  position: absolute;
  display: block;
  width: 0;
  z-index: 1;
  border-style: solid;
  border-color: #161c2e transparent;
  border-width: 20px 30px 0;
  bottom: -20px;
  left: 50%;
  margin-left: -30px;
}

.animate-fadein {
  animation: fadein 2s 1;
}


@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 100;
  }
}


.newItem {
  background-color: #45ff9f;
  color: #000000;
  position: absolute;
  top: 0px;
  right: 0px;
  border-bottom-left-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3px 10px;
  font-weight: bold;
  font-size: 11px;
}

.bubble {
  margin: 0;
  bottom: 30px;
  left: -70px;
  z-index: 10;
}

.bubble:after {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 20px 20px 0;
  border-color: #ffffff transparent;
  display: block;
  width: 0;
  z-index: 1;
  margin-left: -20px;
  bottom: -20px;
  left: 50%;
}

.bubble:before {
  content: '';
  position: absolute;
  border-style: solid;
  border-width: 21px 21px 0;
  border-color: #000000 transparent;
  display: block;
  width: 0;
  z-index: 0;
  margin-left: -21px;
  bottom: -23px;
  left: 50%;
}